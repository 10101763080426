.w-100{
    width: 100% !important;
    .p-button.p-fileupload-choose {
        width: 100% !important;
    }
}
.mw-100{
    max-width: 100% !important;
}
.w-50{
    width: 50% !important;
    display: inline-block;
}
.d-block{
    display: block !important;
}
ul{
    padding-left: 0px !important;
    list-style: none !important;
    li{
        margin-top: 5px;
    }
}
.p-button-default {
    background-color: transparent !important;
    color: #6366F1 !important;
    border: none !important;
    padding: 0px !important;
    margin-left: 7px;
}